import React, { createContext, useState, useContext, useEffect } from "react"

const AmIAWorkerContext = createContext()

export const AmIAWorkerProvider = ({ children }) => {
  const [amIAWorker, setAmIAWorker] = useState({})
  const [emailSubbed, setEmailSubbed] = useState(false)

  useEffect(() => {
    console.log("amIAWorker", amIAWorker)
  })

  const setValues = values => {
    setAmIAWorker(prevAmIAWorker => ({
      ...prevAmIAWorker,
      ...values,
    }))
    console.log("values", values)
  }

  return (
    <AmIAWorkerContext.Provider
      value={{ amIAWorker, setValues, emailSubbed, setEmailSubbed }}
    >
      {children}
    </AmIAWorkerContext.Provider>
  )
}

export const useAmIAWorker = () =>
  useContext(AmIAWorkerContext) || { amIAWorker: "" }

import "./src/styles/global.css"
import "typeface-muli"
import "typeface-raleway"
import "typeface-abril-fatface"
import React from "react"

import { AmIAWorkerProvider } from "./src/contexts/AmIAWorkerContext"

export const wrapRootElement = ({ element }) => (
  <AmIAWorkerProvider>{element}</AmIAWorkerProvider>
)
